import React from "react"
import Feature from "../components/Feature"
import FeatureList from "../components/FeatureList"
import Page from "../components/Page"
import ProjectList from "../components/ProjectList"

import desktop_app from "../images/desktop_app.png"
import mobile_app from "../images/mobile_app.png"
import web_application from "../images/web_application.png"
import our_process from "../images/our_process.png"
import {
  title,
  paragraph,
  contact,
  image,
} from "../styles/technology.module.scss"
import { Link } from "gatsby"
/*import angular from "../images/technologies/angular.png"
import cpp from "../images/technologies/cpp.svg"
import cs from "../images/technologies/cs.png"
import css from "../images/technologies/css.svg"
import html from "../images/technologies/html.png"
import java from "../images/technologies/java.jpg"
import js from "../images/technologies/js.png"
import mysql from "../images/technologies/mysql.png"
import react from "../images/technologies/react.svg"
import php from "../images/technologies/php.svg"
import gatsby from "../images/technologies/gatsby.svg"*/
import Layout from "../components/Layout"
import HeroTitle from "../components/HeroTitle"
import { useTranslation } from "react-i18next"
import Seo from "../SEO"

const Technology = () => {
  const [t] = useTranslation()

  return (
    <Page>
      <Seo
        title="Websites, Desktop und Apps – Gemeinnützig und Günstig!"
        description="Wir entwickeln flexibel und maßgeschneidert Ihre Anwendung: Von effizienter Back-End-Entwicklung, über Software-Migration, bis zur Optimierung von Prozessen."
        keywords={[
          "Qualität",
          "Website",
          "App",
          "Sozial",
          "Günstig",
          "Gemeinnützig",
          "Digitalisierung",
        ]}
      ></Seo>
      <Layout>
        <HeroTitle text={t("Our technologies")} />
        <FeatureList>
          <Feature
            titleString={t("Web applications")}
            imageFile={web_application}
            textString={t("Web applications Paragraph")}
          />
          <Feature
            titleString={t("Android applications")}
            imageFile={mobile_app}
            textString={t("Android applications Paragraph")}
          />
          <Feature
            titleString={t("Desktop applications")}
            imageFile={desktop_app}
            textString={t("Desktop applications Paragraph")}
          />
        </FeatureList>
        {/*<h1 className={styles.title}>{t("Used technologies")}</h1>
        <div className={styles.technologies}>
          <img src={angular} alt="Angular" className={styles.icon} />
          <img src={css} alt="CSS" className={styles.icon} />
          <img src={html} alt="HTML" className={styles.icon} />
          <img src={js} alt="JavaScript" className={styles.icon} />
          <img src={cpp} alt="C++" className={styles.icon} />
          <img src={cs} alt="C#" className={styles.icon} />
          <img src={java} alt="Java" className={styles.icon} />
          <img src={mysql} alt="MySQL" className={styles.icon} />
          <img src={react} alt="React" className={styles.icon} />
          <img src={php} alt="PHP" className={styles.icon} />
          <img src={gatsby} alt="Gatsby" className={styles.icon} />
        </div>*/}
        <h1 className={title}>{t("Our process")}</h1>
        <img src={our_process} alt="Process" className={image} />
        <h1 className={title}>{t("Your advantage")}</h1>
        <p className={paragraph}>{t("Advantage 1")}</p>
        <p className={paragraph}>{t("Advantage 2")}</p>
        <p className={paragraph}>{t("Advantage 3")}</p>
        <h1 className={title}>{t("Our projects")}</h1>
        <ProjectList />
        <h1 className={title}>
          {t("We have your interest?")}
          <br /> {t("Contact us")}
        </h1>
        <Link className={contact} to="/contact">
          Kontakt
        </Link>
      </Layout>
    </Page>
  )
}

export default Technology
